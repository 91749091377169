import gsap from "gsap";


const bar = document.querySelector(".loading__bar--inner");
const counter_num = document.querySelector(".loading__counter--number")
let c = 0;

let barInterval = setInterval(() => {
    bar.style.width = c + "%";
    counter_num.innerText = c + "%";
    c++;
    if(c === 101) {
        clearInterval(barInterval);
        gsap.to(".loading__bar", {
            delay: .5,
            duration: 1.3,
            rotate: "90deg",
            left: "180%",
            opacity:0,
        });

        gsap.to(".loading__text", {
            delay: .5,
            duration: 2,
            rotate: "-90deg",
            right: "180%",
            opacity:0,
        });

        gsap.to(".loading__counter", {
            delay: .5,
            duration: 3,
            top: "265%",
            opacity:0,
        });

        gsap.to(".loading__box", {
            delay: .5,
            duration: 1.15,
            paddingTop: "45px",
            height: "600px",
            width: "800px",
            borderRadius: "8%",
            opacity: 0,
            duration: 3,
        });
        
        gsap.to(".loading__svg", {
            delay:1,
            duration: 5,
            height:"45%",
            width:"45%",
            opacity: 1,
            rotate: "360deg",
        });

        gsap.to(".loading__box", {
            delay: 3,
            height: "1600px",
            width: "1800px",
            borderRadius: "8%",
            duration: 3,
            opacity: 0,
        });

        gsap.to(".loading", {
            delay:7,
            duration: 5,
            background: "transparent",
            opacity: 0,

        });

        gsap.to(".loading__svg", {
            paused: 1,
            delay: 5,
            duration: 3,
            opacity: 0,
        });

        gsap.to(".loading", {
            delay: 10,
            zIndex: 0,

        });
    }
}, );